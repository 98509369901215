import * as React from 'react'

import { styled, spacing, Text } from '@thg-commerce/enterprise-theme'
import { useConfig, useLogger } from '@thg-commerce/enterprise-core'

import { BaseWidgetProps } from '../types'

const NotSupportedBox = styled.div`
  ${Text('bodyText', 'default')};
  padding: ${spacing(2)};
  margin-bottom: ${spacing(2)};
  border: 1px solid ${(props) => props.theme.colors.palette.greys.lighter};
  color: ${(props) => props.theme.colors.palette.greys.grey};
  text-align: center;
`

export type NotSupportedWidgetProps = BaseWidgetProps

export const NotSupportedWidget = ({
  widgetType,
  pagePath,
}: NotSupportedWidgetProps) => {
  const logger = useLogger()
  const config = useConfig()

  logger.warn(
    `Tried to render widget ${widgetType} on page ${pagePath} which is not supported.`,
  )

  const shouldRender = !config.IS_PRODUCTION

  if (!shouldRender) {
    return null
  }

  return (
    <NotSupportedBox>
      Widget type {widgetType} is not currently supported on the platform.
    </NotSupportedBox>
  )
}
