import React from 'react'
import { useQuery } from '@apollo/react-hooks'

import {
  ComponentName,
  ComponentWidgetsData,
  ComponentWidgetsVariables,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/ComponentWidgets'

import { WidgetRenderer } from '../WidgetRenderer'
import {
  Country,
  Currency,
} from '@thg-commerce/enterprise-network/src/generated/graphql'

import { ComponentWidgets as COMPONENT_WIDGETS } from './ComponentWidgets.graphql'

export interface ComponentWidgetRendererProps {
  componentName: ComponentName
  currency: Currency
  shippingDestination: Country
  ssr?: boolean
}

export const ComponentWidgetRenderer = (
  props: ComponentWidgetRendererProps,
) => {
  const { data } = useQuery<ComponentWidgetsData, ComponentWidgetsVariables>(
    COMPONENT_WIDGETS,
    {
      ssr: props.ssr,
      variables: {
        name: props.componentName,
        currency: props.currency,
        shippingDestination: props.shippingDestination,
      },
    },
  )

  if (!data || data.componentWidgets.length === 0) return null

  return <WidgetRenderer widgets={data.componentWidgets} />
}
