import React from 'react'
import { InView } from 'react-intersection-observer'

import {
  useHeaderHeight,
  useSiteConfig,
  useTheme,
} from '@thg-commerce/enterprise-core'
import {
  pushToDataLayer,
  pushToEnhancedDataLayer,
} from '@thg-commerce/enterprise-metrics/src/data_layer'
import {
  PromotionGA4,
  pushToEventGA4,
} from '@thg-commerce/enterprise-metrics/src/data_layer/pushToDataLayer/utils'
import { Widget } from '@thg-commerce/enterprise-network/src/generated/graphql'
import { styled } from '@thg-commerce/enterprise-theme'

import { NotSupportedWidget } from '../NotSupportedWidget'

import { loadWidget } from './widgetMap'

export interface WidgetRendererProps {
  widgets: Omit<Widget, 'query'>[]
  path?: string
  pageParams?: {
    [key: string]: string
  }
}

export const ScrollToContainer = styled.div<{ headerHeight: number }>`
  scroll-margin-top: ${(props) => `${props.headerHeight}px`};
`
export const WidgetRenderer = (props: WidgetRendererProps) => {
  const headerHeight = useHeaderHeight()
  const theme = useTheme()
  const { useGA4EnhancedEcom } = useSiteConfig()

  const topProductCategorySet = props.widgets.find(
    (item) => item['__typename'] === 'TopProductCategorySet',
  )

  const widgets = React.useMemo(
    () =>
      props.widgets
        .filter((widget) => widget['__typename'] !== 'TopProductCategorySet')
        .map((widget, index) => {
          const widgetComponent = loadWidget(widget['__typename'])
          if (widgetComponent && typeof window !== 'undefined') {
            pushToDataLayer({
              type: 'elysiumEvent',
              eventData: {
                eventAction: 'Viewed',
                eventCategory: 'Widget Track',
                eventLabel: widget['__typename'],
                eventLabelValue: widget.id,
                eventPage: props.path,
              },
            })
          }

          if (widgetComponent) {
            const component = React.createElement(widgetComponent, {
              ...(topProductCategorySet && {
                categories: { ...topProductCategorySet },
              }),
              ...widget,
              widgetIndex: index,
              widgetType: widget['__typename'],
              key: widget.id,
              pagePath: props.path,
              pageParams: props.pageParams,
            })

            const getLayoutWidth = () => {
              return widget['backgroundColour']
                ? theme.widget.default.layout.fullWidth
                : theme.widget[widget['__typename']]?.layout?.maxWidth ||
                    theme.widget.default.layout.maxWidth
            }

            return (
              <ScrollToContainer
                id={`${widget['__typename']}_${index}`}
                headerHeight={headerHeight}
                data-testid={`widget-renderer-scroll-to-container-${widget.id}`}
                onClick={() => {
                  useGA4EnhancedEcom &&
                    pushToEventGA4<PromotionGA4>({
                      event: 'select_promotion',
                      ecommerce: {
                        promotion_name: widget['__typename'],
                        promotion_id: widget.id,
                      },
                    })
                }}
              >
                <div
                  style={{
                    margin: 'auto',
                    maxWidth: getLayoutWidth(),
                  }}
                >
                  <InView
                    triggerOnce={true}
                    onChange={(inView) => {
                      if (useGA4EnhancedEcom && inView) {
                        pushToEnhancedDataLayer({
                          event: 'ecom_event',
                          event_name: 'view_promotion',
                          promotion_name: widget['widgetDescription'],
                          promotion_id: widget.id,
                          promotion_slot: index,
                          promotion_page:
                            typeof window !== undefined &&
                            window.location.pathname,
                          promotion_type: widget['__typename'],
                        })
                      }
                    }}
                  >
                    {component}
                  </InView>
                </div>
              </ScrollToContainer>
            )
          }
          return (
            <NotSupportedWidget
              widgetType={widget['__typename']}
              pagePath={props.path || ''}
              key={widget.id}
            />
          )
        }),
    [
      props.widgets,
      headerHeight,
      props.pageParams,
      props.path,
      theme.widget,
      topProductCategorySet,
      useGA4EnhancedEcom,
    ],
  )

  return <React.Fragment>{widgets}</React.Fragment>
}

export default WidgetRenderer
